import React from 'react';

const Awards = ({ data }) => {
  const awards = data.allSanityAwards.edges;
  return (
    <section>
      <h1 className="section-header">Awards</h1>
        {awards.map(awardNode => {
          const award = awardNode.node
          return (
            <div key={award.title} className="my-5">
              <h1 className="item-header font-semibold mb-2">
                {award.title}
              </h1>
              <p>{award.description}</p>
            </div>
          )
        })}
    </section>
  );
}

export default Awards;
