import React from 'react';

const ListItemEmbedded = ({ category, items }) => (
  <div className="my-1">
    <p className="inline-block font-bold">{category}</p>
    <p className="inline-block ml-4">{items}</p>
  </div>
);

export default ListItemEmbedded;
