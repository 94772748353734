import React from 'react';

const Summary = ({ data }) => {
  const summary = data.allSanityPerson.edges[0].node.summary[0].children[0].text;
  return (
  <section className="py-5 border-b border-neutral-300 lg:flex items-center">
    <div className="my-5">
      <img
        className="rounded-full mx-auto w-32 sm:w-48 lg:w-full xl:w-4/5"
        src="./profile.jpg"
        alt="profile"
      />
    </div>
    <div className="my-5">
      <p className="text-center leading-relaxed lg:text-left lg:mx-8 lg:text-lg">
        {summary}
      </p>
    </div>
  </section>
)};

export default Summary;

