import React from 'react';
import moment from 'moment';

const Experience = ({ data }) => {
  const allExperience = data.allSanityExperience.edges
  return (
    <section>
      <h1 className="section-header">Experience</h1>
        {allExperience.map((experienceNode, i) => {
          const experience = experienceNode.node
          return (
            <article className="my-5" key={`${experience.company}-${i}`}>
              <h2 className="item-header">
                <a href={experience.url} style={{textDecoration: 'underline'}}>
                  {experience.company}
                </a>, {experience.location}
              </h2>
              {experience.role.map((role, i) => (
                <h3 key={`${role.title}-${i}`} className="font-medium font-xl">
                  {role.title} &ndash; {moment(role.start).format("MMM YYYY")} - {role.end ? moment(role.end).format("MMM YYYY") : 'Present'}
                </h3>
              ))}
              {experience.description.map((item, i) => (
                <p key={`${experience.company}-${i}`} className="py-2">{item.children[0].text}</p>
              ))}
            </article>
          )})
        }
    </section>
  );
}

export default Experience;
