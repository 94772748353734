import React from 'react';

const Education = ({ data }) => {
  const allEducation = data.allSanityEducation.edges;
  return (
    <section className="mb-5">
      <h1 className="section-header mb-5">Education</h1>
      {allEducation.map(educationEdge => {
        const education = educationEdge.node;
        return (
          <div className="my-2" key={education.degree}>
            <h2 className="item-header text-xl">
              <a href={education.url}>
                {education.institution}
              </a>
              , {education.location}</h2>
            <h3 className="item-sub text-lg">{education.degree}</h3>
            <p>
              {education.start} - {education.end} {education.distinction ? `| ${education.distinction}` : null}
            </p>
          </div>
        )}
      )}
    </section>
  );
}

export default Education;
