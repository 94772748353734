import React from 'react';
import ListItemEmbedded from './list_item_embedded';

const Skills = ({ data }) => {
  const allSkills = data.allSanitySkill.nodes;
  return (
    <section>
      <h1 className="section-header">Skills</h1>
      {allSkills.map(node => {
        const title = node.title
        const type = node.type[0];
        const skills = node.skills
        return (
          <div key={title} className="my-5">
            <h1 className="item-header font-semibold mb-2">
              {title}
            </h1>
            {skills.map(subskill => (
              <span key={subskill.name}>
                {type === 'tag' && (
                  <span key={subskill.name} className="tag">
                    {subskill.name}
                  </span>
                )}
                {type === 'list' && (
                  <ListItemEmbedded category={subskill.name} items={subskill.examples} />
                )}
              </span>
            ))}
          </div>
        )
      })}
    </section>
  )
}

export default Skills;
