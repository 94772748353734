import React from 'react';
import { graphql } from 'gatsby';
import {
  Header,
  Summary,
  Experience,
  Volunteer,
  Awards,
  Skills,
  Education,
  Footer,
  SEO
} from '../components';
import '../main.css';

const Home = ({data}) => (
  <main className="antialiased text-neutral-900 bg-neutral-100 min-h-screen sm:p-5">
    <SEO title="Resume" />
    <div className="container mx-auto shadow bg-white py-5 px-10">
      <Header data={data} />
      <Summary data={data} />
      <div className="border-b border-neutral-300 pb-2 my-5 lg:flex">
        <div className="lg:w-2/3 lg:pr-8">
          <Experience data={data} />
          <Education data={data} />
        </div>
        <div className="lg:w-1/3 lg:pl-8 lg:border-l lg:border-neutral-300">
          <Awards data={data} />
          <Skills data={data} />
          <Volunteer data={data} />
        </div>
      </div>
      <Footer data={data} />
    </div>
  </main>
);

export default Home;

export const ContactQuery = graphql`
{
  allSanityVolunteering {
    nodes {
      description
      name
    }
  }
  allSanitySocial {
    nodes {
      service
      url
    }
  }
  allSanitySkill {
    nodes {
      title
      type
      skills {
        name
        examples
      }
    }
  }
  allSanityPerson {
    edges {
      node {
        email
        fullname
        location
        role
        summary {
          children {
            text
          }
        }
      }
    }
  }
  allSanityExperience(sort: {order: DESC, fields: role___start}) {
    edges {
      node {
        location
        url
        company
        description {
          children {
            text
          }
        }
        role {
          title
          start
          end
        }
      }
    }
  }
  allSanityEducation {
    edges {
      node {
        institution
        location
        start
        url
        distinction
        degree
        end
      }
    }
  }
  allSanityAwards {
    edges {
      node {
        description
        title
      }
    }
  }
}
`
