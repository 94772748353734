import React from 'react';
import HeartIcon from '../assets/heart.svg';
import SocialIcon from './social_icon';

const Footer = ({ data }) => {
  const allSocial = data.allSanitySocial.nodes;
  return (
    <footer className="py-6 mx-auto items-center justify-between md:flex">
      <div className="items-center flex tracking-wide mb-5 md:mb-0 justify-center text-sm ml-2">
        <span className="inline-block mr-1">
          © {new Date().getFullYear()} | Developed with
        </span>
        <span className="inline-block mr-1">
          <HeartIcon
            className="h-3 w-3 fill-current"
            style={{ color: '#de3618' }}
          />
        </span>
        <span className="inline-block mr-1">by</span>
        <a
          className="text-primary-500 hover:text-primary-700 font-bold"
          href="https://michaelwnelson.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Michael Nelson
        </a>
      </div>
      {allSocial && (
        <div className="flex items-center justify-center ml-2">
          {allSocial.map(social => (
            <a
              key={social.service}
              className="footer-social-link"
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              title={social.service}
            >
              <SocialIcon type={social.service} />
            </a>
          ))}
        </div>
      )}
    </footer>
  );
}

export default Footer;
