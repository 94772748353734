import React from 'react';

const Volunteer = ({ data }) => {
  const allVolunteer = data.allSanityVolunteering.nodes;
  return (
    <section>
      <h1 className="section-header">Volunteering</h1>
      {allVolunteer.map(volunteer => (
        <article className="my-3" key={volunteer.name}>
          <h2 className="item-header text-lg">{volunteer.name}</h2>
          <p className="py-1">{volunteer.description}</p>
        </article>
      ))}
    </section>
  );
}

export default Volunteer;
