import React from 'react';
import Contact from './contact';

const Header = ({ data }) => {
  const name = data.allSanityPerson.edges[0].node.fullname;
  const role = data.allSanityPerson.edges[0].node.role;

  const email = data.allSanityPerson.edges[0].node.email;
  const location = data.allSanityPerson.edges[0].node.location;
  const linkedin = data.allSanitySocial.nodes.filter(social => social.service === 'LinkedIn')[0].url;
  const contacts = {linkedin, email, location}

  return (
    <header className="border-b border-neutral-300 pb-2 md:flex items-center justify-between">
      <div>
        <h1 className="text-primary-500 text-4xl md:text-5xl font-bold tracking-wide leading-tight">
          {name}
        </h1>
        <h2 className="font-light text-lg md:text-2xl text-primary-900 leading-relaxed tracking-widest">
          {role}
        </h2>
      </div>
      <div className="mt-5 md:mt-0 md:border-l md:border-neutral-300 md:pl-4">
        {Object.keys(contacts).map(key => (
          <Contact key={key} field={key} value={contacts[key]} />
        ))}
      </div>
    </header>
  );
}

export default Header;
